import { AppProps } from 'next/app'
import '@/styles/globals.scss'
import { useCookies } from '@/app/hooks/useCookies'
import React, { useState, useCallback } from 'react'
import dynamic from 'next/dynamic'
import { ThemeProvider } from '@/app/contexts/ThemeContext'
import CookieConsent from '@/app/components/UI/CookieConsent'
import Header from '@/app/components/UI/Header'
import Footer from '@/app/components/UI/Footer'
import Head from 'next/head'
import useGoogleTagManager from '@/app/hooks/useGoogleTagManager'

const DynamicAlertModal = dynamic(() => import('@/app/components/UI/AlertModal'), { ssr: false })

const App = ({ Component, pageProps }: AppProps) => {
  const { cookiePreferences, handleConsent, hasCookie } = useCookies()
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false)

  const handleAccept = useCallback(() => {
    handleConsent({ necessary: true, analytics: true, advertising: true, performance: true })
  }, [handleConsent])

  const handleDecline = useCallback(() => {
    handleConsent({ necessary: true, analytics: false, advertising: false, performance: false })
  }, [handleConsent])

  useGoogleTagManager()

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <ThemeProvider>
        <Header setIsAlertModalOpen={setIsAlertModalOpen} />
        <Component {...pageProps} cookiePreferences={cookiePreferences} />
        {/*isHomeLoaded && <CookieConsent
          onAccept={handleAccept}
          onDecline={handleDecline}
          buttonText="Aceitar todos os cookies"
          declineButtonText="Apenas os necessários"
          text='Ao clicar em "Aceitar todos os cookies", concorda com o armazenamento de cookies no seu dispositivo para melhorar a navegação e analisar a utilização do site, além de ajudar nas nossas iniciativas de marketing.'
          cookieURL='/cookies'
          hasCookie={hasCookie}
        />*/}
        {isAlertModalOpen && <DynamicAlertModal setIsAlertModalOpen={setIsAlertModalOpen} />}
        <Footer />
      </ThemeProvider>
    </>
  )
}

export default App
