import React from "react"
import LogoContainer from "../../LogoContainer"
import { useTheme } from "@/app/contexts/ThemeContext"
import CustomLink from "../../CustomLink"
import styles from "@/styles/mobile/Footer.module.scss"

const FooterMobile: React.FC = () => {
    const { theme } = useTheme()

    const srcLogo = theme === "light" ? "https://gabcomercio.azureedge.net/azul-agro/general/logo.svg" : "https://gabcomercio.azureedge.net/azul-agro/general/logoDark.svg"
    const srcLineLogo = theme === "light" ? "https://gabcomercio.azureedge.net/azul-agro/general/lineLogo.svg" : "https://gabcomercio.azureedge.net/azul-agro/general/lineLogoDark.svg"
    const srcLogoNH = theme === "light" ? "https://gabcomercio.azureedge.net/azul-agro/general/logoNH.svg" : "https://gabcomercio.azureedge.net/azul-agro/general/logoNHDark.svg"
    const srcLogoGAB = theme === "light" ? "https://gabcomercio.azureedge.net/azul-agro/general/gab.svg" : "https://gabcomercio.azureedge.net/azul-agro/general/gabDark.svg"

    const srcMedias = {
        facebook: theme === "light" ? "https://gabcomercio.azureedge.net/azul-agro/general/facebook.svg" : "https://gabcomercio.azureedge.net/azul-agro/general/facebookDark.svg",
        instagram: theme === "light" ? "https://gabcomercio.azureedge.net/azul-agro/general/instagram.svg" : "https://gabcomercio.azureedge.net/azul-agro/general/instagramDark.svg",
        linkedin: theme === "light" ? "https://gabcomercio.azureedge.net/azul-agro/general/linkedin.svg" : "https://gabcomercio.azureedge.net/azul-agro/general/linkedinDark.svg",
    }

    return (
        <footer className={styles.footer}>
            <LogoContainer srcLogo={srcLogo} srcLineLogo={srcLineLogo} srcLogoNH={srcLogoNH} widthLogo={126} heightLogo={27} widthLogoNH={133} heightLogoNH={22} />
            <div className={styles.medias}>
                <CustomLink href=""><img src={srcMedias.facebook} alt="Acessar facebook da Azul Agro" width={32} height={32} loading="lazy" /></CustomLink>
                <CustomLink href=""><img src={srcMedias.instagram} alt="Acessar instagram da Azul Agro" width={32} height={32} loading="lazy" /></CustomLink>
                <CustomLink href=""><img src={srcMedias.linkedin} alt="Acessar linkedin da Azul Agro" width={32} height={32} loading="lazy" /></CustomLink>
            </div>
            <div className={styles.ibama}>
                <img src="https://gabcomercio.azureedge.net/azul-agro/general/ibama.svg" alt="" loading="lazy" height={83} width={73} />
                <div>
                    <span>“PAZ NO TRÂNSITO</span>
                    <span>COMEÇA POR VOCÊ”</span>
                </div>
            </div>
            <div>
                <CustomLink href="https://www.gab.com.br" target="_blank"><img src={srcLogoGAB} alt="Acessar o site do Grupo Águia Branca" loading="lazy" width={152} height={40} /></CustomLink>
            </div>
            <div className={styles.links}>
                <CustomLink href="https://privacidadecomercio.aguiabranca.com.br" target="_blank">Política de privacidade</CustomLink>
                <CustomLink href="https://compliancedivisaocomercio.com.br" target="_blank">Canal de denúncia</CustomLink>
            </div>
            <div className={styles.copyright}>
                <span className="defesa">©2023 Azul Agro Máquinas & Equipamentos. Uma concessionária New Holland. Todos os direitos reservados.</span>
            </div>
        </footer>
    )
}

export default FooterMobile